import { useState } from "react";
import {
  Container,
  Row,
  Form,
  Button,
  ProgressBar,
  Alert,
} from "react-bootstrap";
import axiosInstance from "./utils/axios";
import QRCode from 'qrcode.react';

function App() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [inputUrlAAB, setInputUrlAAB] = useState();
  const [progress, setProgress] = useState();
  const [isStartUrlConvert, setIsStartUrlConvert] = useState();
  const [isUploadSuccess, setIsUploadSuccess] = useState();
  const [isComplete = true, setIsComplete] = useState();
  const [error, setError] = useState();
  const [result, setResult] = useState();

  const submitHandler = (e) => {
    e.preventDefault(); //prevent the form from submitting

    const resetBooleanState = isComplete => {
      setIsUploadSuccess(false)
      setIsStartUrlConvert(false)
      setIsComplete(isComplete)
    }
    //Clear the error message
    resetBooleanState(false)
    setError("");
    setResult();

    const onConvertToApkSuccess = response => {
      resetBooleanState(true)
      const result = response.data
      if(result.success) result.downloadUrl = `${window.location.origin}/${result.armv7FilePath}`
      setResult(result);
    }

    if(inputUrlAAB){
      setIsStartUrlConvert(true)
      axiosInstance.post("/upload_aab_url", {inputUrlAAB}, {headers: {
        "Content-Type": "application/json",
      }})
        .then(onConvertToApkSuccess)
        .catch(error => {
          resetBooleanState(true)
          setInputUrlAAB('')
          console.error(error)
          setError("Sorry! Something went wrong with AAB Url! Try upload file directly instead!");
        })
    }
    else{
      let formData = new FormData();

      formData.append("file", selectedFiles[0]);
  
      axiosInstance.post("/upload_file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
            if(!isUploadSuccess && data.loaded === data.total) setIsUploadSuccess(true)
          },
        })
        .then(onConvertToApkSuccess)
        .catch((error) => {
          resetBooleanState(true)

          const { code } = error?.response?.data;
          switch (code) {
            case "FILE_MISSING":
              setError("Please select a file before uploading!");
              break;
            case "LIMIT_FILE_SIZE":
              setError("File size is too large. Please upload files below 1GB!");
              break;
            case "INVALID_TYPE":
              setError(
                "This file type is not supported! Only .aab files are allowed"
              );
              break;
  
            default:
              setError("Sorry! Something went wrong. Please try again later");
              break;
          }
        });
    }
  };
  return (
    <Container>
      <Row>
          <Form
            action="/upload_file"
            method="post"
            encType="multipart/form-data"
            onSubmit={submitHandler}
          >
            <p/>
            <Form.Control 
              type="text" 
              disabled={isStartUrlConvert}
              placeholder="Enter AAB URL or Choose AAB File" 
              value={inputUrlAAB}
              onChange={e => setInputUrlAAB(e.target.value)}
            />
            <p/>
            <Form.Group>
              <Form.File
                id="exampleFormControlFile1"
                name="file"
                onChange={(e) => {
                  setSelectedFiles(e.target.files);
                }}
              />
            </Form.Group>
            <p/>
            <Form.Group>
              <Button disabled={!isComplete} variant="info" type="submit">
                Upload
              </Button>
            </Form.Group>
            <p/>
            {error && <Alert variant="danger">{error}</Alert>}
            {!error && progress && (
              <ProgressBar now={progress} label={`${progress}%`} />
            )}
            {/* <Alert variant="info">Upload <a target={"blank"} href={"https://google.com"}>https://google.com</a></Alert> */}
            {isStartUrlConvert && <Alert variant="secondary">Handling URL: <a href={inputUrlAAB}>{inputUrlAAB}</a>. This should takes a few minutes...</Alert>}
            {isUploadSuccess && <Alert variant="info">Upload Success</Alert>}
            {isUploadSuccess && !result && <Alert variant="secondary">Decompiling AAB...</Alert>}
            {result && result.success && <Alert variant="info">
              Decompile Success in {result.decompileDuration}s, Download Url:
              <p/>
              <a href={result.downloadUrl}>{result.downloadUrl}</a>
              <p/>
              <QRCode value={result.downloadUrl} size={256}/>
              </Alert>}
            {result && !result.success && <Alert variant="danger">Decompile Failed!</Alert>}
          </Form>
      </Row>
    </Container>
  );
}

export default App;
